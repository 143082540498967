import React from "react";
import BrandPortalNav from "./BrandPortalNav";

export default class CampaignSetupStepThree extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: 'campaign_setup',
      errors: "",
      loading: false,
      rebateAmount: this.props.brand.brand_template.rebate_amount,
      selectedFiles: this.props.brand.brand_template.reels,
      newlySelectedFiles: [],
      filesUploaded: false,
    };

    this.changeRebateAmount = this.changeRebateAmount.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.handleRefClick = this.handleRefClick.bind(this)
    this.saveProgress = this.saveProgress.bind(this)
    this.setActivePage = this.setActivePage.bind(this)
  }

  componentDidMount() {
    this.fileInputRef = React.createRef();
  }

  componentWillUnmount() {
    this.state.selectedFiles.forEach((file) => {
      URL.revokeObjectURL(file.previewUrl)
    })
  }

  changeRebateAmount(event) {
    this.setState({
      rebateAmount: event.target.value
    })
  }

  handleFileChange(event) {
    const files = Array.from(event.target.files);

    const newFiles = files.map((file) => ({
      file,
      previewUrl: URL.createObjectURL(file),
      name: file.name
    }))

    this.setState({
      filesUploaded: true,
      selectedFiles: [...this.state.selectedFiles, ...newFiles],
      newlySelectedFiles: newFiles,
    })
  }

  handleRefClick() {
    this.refs.fileInputRef.click();
  }

  saveProgress() {
    let that = this;

    this.setState({
      loading: true,
    })

    const formData = new FormData();
    let data = JSON.stringify({
      brand_id: this.props.brand.id,
      rebate_amount: this.state.rebateAmount,
    })
    formData.append('brand', data)

    if (this.state.filesUploaded) {
      this.state.newlySelectedFiles.forEach((image) => {
        formData.append('files[]', image.file)
      })
    }

    fetch(this.props.brand.campaign_setup_save_url, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          loading: false,
          errors: data.errors,
        })
      } else {
        this.goToUrl(that.props.brand.campaign_setup_next_step_url)
      }
    })
  }

  setActivePage(e) {
    this.setState({
      activePage: e
    })
  }

  goToUrl(url) {
    if (url) {
      window.location.assign(url)
    }
  }

  render() {
    let { brand, showdrop_logo_alt } = this.props;
    let {
      activePage,
      errors,
      loading,
      rebateAmount,
      filesUploaded,
      selectedFiles,
    } = this.state;

    return (
      <div className="container-fluid bg-brand-portal-v2 fs-14 admin full-height">
        <div className="row">
          <div className="col col-lg-2" style={{ top:"0",position:"sticky",height:"100vh" }}>
            <BrandPortalNav
              activePage={activePage}
              brand={brand}
              showdropLogo={showdrop_logo_alt}
              onClick={this.setActivePage}
            />
          </div>

          <div className="col col-lg-10">
            <div className="row bg-brand-portal-v2">
              <div className="col">
                <h2 className="caprasimo" style={{ margin:"40px 0 20px" }}>
                  Campaign Setup
                </h2>
              </div>
            </div>

            <div className="row bg-brand-portal-v2">
              <div className="progress-container shadow-light">
                <div className="step">
                  <div style={{ height:"35px",color:"#ccc",textAlign:"left",lineHeight:"1.1",marginLeft:"50px" }}>
                    <p style={{ margin:"0" }}>Brand</p>
                    <p>Information</p>
                  </div>

                  <div style={{ display:"flex" }}>
                    <div style={{ flex:"1",maxWidth:"40px",color:"white",border:"3px solid #20cb9a",backgroundColor:"#20cb9a" }} className="step-number">
                      <i className="fas fa-check"></i>
                    </div>
                    <div style={{ flex:"1",marginTop:"18px",borderTop:"4px solid #20cb9a",color:"#20cb9a" }}></div>
                  </div>
                </div>

                <div className="step">
                  <div style={{ height:"35px",color:"#ccc",textAlign:"left",lineHeight:"1.1",marginLeft:"50px" }}>
                    <p style={{ margin:"0" }}>Product</p>
                    <p>Information</p>
                  </div>

                  <div style={{ display:"flex" }}>
                    <div style={{ flex:"1",maxWidth:"40px",color:"white",border:"3px solid #20cb9a",backgroundColor:"#20cb9a" }} className="step-number">
                      <i className="fas fa-check"></i>
                    </div>
                    <div style={{ flex:"1",marginTop:"18px",borderTop:"4px solid #20cb9a",color:"#20cb9a" }}></div>
                  </div>
                </div>

                <div className="step">
                  <div style={{ height:"35px",color:"black",textAlign:"left",lineHeight:"1.1",marginLeft:"50px" }}>
                    <p style={{ margin:"0" }}>Campaign</p>
                    <p>Details</p>
                  </div>

                  <div style={{ display:"flex" }}>
                    <div style={{ flex:"1",maxWidth:"40px",border:"3px solid black",color:"black" }} className="step-number">3</div>
                    <div style={{ flex:"1",marginTop:"18px",borderTop:"4px solid black" }}></div>
                  </div>
                </div>

                <div className="step">
                  <div style={{ height:"35px",color:"#ccc",textAlign:"left",lineHeight:"3.5",marginLeft:"50px" }}>
                    <p>Complete</p>
                  </div>

                  <div style={{ display:"flex" }}>
                    <div style={{ flex:"1",maxWidth:"40px",border:"3px solid #ccc" }} className="step-number">
                      <i className="fas fa-check"></i>
                    </div>
                    <div style={{ flex:"1" }}></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col bg-white br-10 shadow-light" style={{ padding:"20px 40px",margin:"10px" }}>
                <div style={{ marginBottom:"50px" }}>
                  <div style={{ fontSize:"20px",fontWeight:"bold",marginBottom:"5px" }}>
                    1. Sizzle Reel
                    <span style={{ marginLeft:"5px",fontSize:"10px",color:"#a8a8a8" }}>*optional</span>
                  </div>
                  <div style={{ marginBottom:"5px" }}>If you would like to include a sizzle reel to add more dynamism to your product image, please upload below.</div>
                  <div>
                    <div>The reel must be:</div>
                    <ul>
                      <li>1080px 1080px or 1920px x 1920px</li>
                      <li>15-30 seconds, no audio</li>
                      <li>.mp4 format</li>
                    </ul>
                  </div>
                  <div>
                    <input type="file" style={{ display:"none" }} ref="fileInputRef" onChange={this.handleFileChange} multiple />

                    <div className="clickable" style={{ fontSize:"20px",fontWeight:"bold",margin:"20px 0" }} onClick={this.handleRefClick}>
                      {
                        filesUploaded ? (
                          <i style={{ fontSize:"30px",color:"#20cb9a" }} className="fas fa-check-circle"></i>
                        ) : (
                          <i style={{ fontSize:"30px" }} className="fas fa-image"></i>
                        )
                      }

                      <span style={{ marginLeft:"20px",position:"relative",top:"-4px" }}>
                        {
                          filesUploaded ? (
                            "Reel has been Added!"
                          ) : (
                            "Click to Upload Reel"
                          )
                        }
                      </span>
                    </div>

                    {
                      selectedFiles.length > 0 ? (
                        selectedFiles.map((file, idx) => {
                          return (
                            <div className="target" key={idx}>
                              <div>{file.name}</div>
                            </div>
                          )
                        })
                      ) : (
                        null
                      )
                    }
                  </div>
                </div>

                <div style={{ marginBottom:"50px" }}>
                  <div style={{ fontSize:"20px",fontWeight:"bold",marginBottom:"5px" }}>
                    2. Rebates
                    <span style={{ marginLeft:"5px",fontSize:"10px",color:"#a8a8a8" }}>*optional</span>
                  </div>
                  <div style={{ marginBottom:"5px" }}>If you would like to include a rebate, please indicate how much in the text box below</div>
                  <div>
                    <div className="input-group mb-3 mt-3" style={{ width:"600px" }}>
                      <input type="text" className="form-control" placeholder="Rebate Amount" onChange={this.changeRebateAmount} value={rebateAmount} />
                    </div>
                  </div>
                </div>

                <div style={{ display:"flex",width:"400px",margin:"auto" }}>
                  <div style={{ margin:"auto",textAlign:"center" }}>
                    <button style={{ display:"flex",padding:"15px 30px 15px 20px",justifyContent:"space-between",borderRadius:"10px",border:"none",backgroundColor:"#DCDCDC",color:"black",fontWeight:"bold",width:"155px" }} onClick={loading ? null : (e) => {e.stopPropagation(); this.goToUrl(brand.campaign_setup_previous_step_url)}}>
                      <i className="fas fa-angle-left" style={{ fontSize:"26px" }}></i>

                      <span style={{ fontSize:"18px" }}>Back</span>
                    </button>
                  </div>

                  <div style={{ margin:"auto",textAlign:"center" }}>
                    <button style={{ display:"flex",padding:"15px 20px 15px 30px",justifyContent:"space-between",borderRadius:"10px",border:"none",backgroundColor:"#20cb9a",color:"white",fontWeight:"bold",width:"155px" }} onClick={loading ? null : this.saveProgress} disabled={loading ? 'disabled' : null}>
                      <span style={{ fontSize:"18px" }}>Confirm</span>

                      <i className={`fas ${loading ? 'fa-circle-notch fa-spin' : 'fa-angle-right'}`} style={{ fontSize:"26px" }}></i>
                    </button>
                  </div>
                </div>

                {
                  errors ? (
                    <div style={{ padding:"5px 0 0",fontSize:"14px",textAlign:"center",color:"red" }}>{errors}</div>
                  ) : (
                    null
                  )
                }
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
