import React from "react"

export default class BrandPortalNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  goToUrl(url) {
    if (url) {
      window.location.assign(url)
    }
  }

  render () {
    let { activePage, brand, showdropLogo } = this.props;

    let displayLogo = () => {
      if (brand.logo) {
        return (
          <img className="h-auto pdtb-20" style={{ width:"100%" }} src={brand.logo}></img>
        )
      } else {
        return (
          <h1 className="pdtb-20">{brand.name}</h1>
        )
      }
    }

    return (
      <div className="nav flex-column nav-pills fs-18 mt-10 mb-20" aria-orientation="vertical">
        <div className="nav-link">
          {displayLogo()}
        </div>

        {
          brand.active_wave ? (
            <div className={`nav-link clickable ${activePage == 'active_campaign' ? 'active bg-white br-10 color-black shadow-light' : 'color-light-gray'}`} style={{ borderRadius:"10px" }} onClick={() => this.goToUrl(brand.active_wave_url)}>
              <i className="fas fa-th-large" style={{ paddingRight:"10px" }}></i>

              Active Campaign
            </div>
          ) : (
            null
          )
        }

        <div className={`nav-link clickable ${activePage == 'all_campaigns' ? 'active bg-white br-10 color-black shadow-light' : 'color-light-gray'}`} style={{ borderRadius:"10px" }} onClick={() => this.goToUrl(brand.all_waves_url)}>
          <i className="fas fa-th-list" style={{ paddingRight:"10px" }}></i>

          All Campaigns
        </div>

        <div className={`nav-link clickable ${activePage == 'campaign_setup' ? 'active bg-white br-10 color-black shadow-light' : 'color-light-gray'}`} style={{ borderRadius:"10px" }} onClick={() => this.goToUrl(brand.campaign_setup_url)}>
          <i className="fas fa-crosshairs" style={{ paddingRight:"10px" }}></i>

          Campaign Setup
        </div>

        <div className="nav-link" style={{ position:"absolute",bottom:"0" }}>
          <img className="" src={showdropLogo} style={{ width:"100%" }} />
        </div>
      </div>
    );
  }
}
