import React from "react"
import Nav from "../shared/Nav";
import EditHeader from "../shared/headers/EditHeader";
import Errors from "../shared/Errors";
import AdminHeader from "../shared/headers/AdminHeader";

export default class Edit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      closeDate: this.props.brand_template.close_date,
      errors: "",
      launchDate: this.props.brand_template.launch_date,
      name: this.props.brand_template.name,
      onDesktop: true,
    };

    this.changeCloseDate = this.changeCloseDate.bind(this)
    this.changeLaunchDate = this.changeLaunchDate.bind(this)
    this.changeName = this.changeName.bind(this)
    this.editRecord = this.editRecord.bind(this)
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  changeCloseDate(event) {
    this.setState({
      closeDate: event.target.value
    })
  }

  changeLaunchDate(event) {
    this.setState({
      launchDate: event.target.value
    })
  }

  changeName(event) {
    this.setState({
      name: event.target.value
    })
  }

  editRecord() {
    let that = this;
    const formData = new FormData();
    let data = JSON.stringify({
      close_date: this.state.closeDate,
      launch_date: this.state.launchDate,
      name: this.state.name,
    })
    formData.append('brand_template', data)

    fetch(this.props.brand_template.edit_path, {
      method: 'PUT',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          errors: data.errors
        })
      } else {
        window.location.assign(data.url)
      }
    })
  }

  render () {
    let {
      brand_template,
      showdrop_logo,
      url_new,
    } = this.props;
    let {
      closeDate,
      errors,
      launchDate,
      name,
      onDesktop,
    } = this.state;
    let pageName = 'brands';

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <EditHeader
              name={`${brand_template.name}`}
              url_show={brand_template.url}
              url_new={url_new}
            />

            <dl className="row">
            <dt className="col-sm-3 text-right" style={{ marginBottom:"10px" }}>
                <span style={{ color:"red",fontSize:"10px" }}>*Required</span>
              </dt>
              <dd className="col-sm-9"></dd>

              <dt className="col-sm-3 text-right">
                <span style={{ color:"red",fontSize:"16px" }}>*</span>Name
                <div className="fs-10 thin">(Name of Template)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Name of Template" onChange={this.changeName} value={name} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                <span style={{ color:"red",fontSize:"16px" }}>*</span>Close Date
                <div className="fs-10 thin">(The Date when we lock the brands from changing this template)</div>
                <div className="fs-10 thin">(Ex. 02/10/25)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Close Date" onChange={this.changeCloseDate} value={closeDate} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right">
                <span style={{ color:"red",fontSize:"16px" }}>*</span>Launch Date
                <div className="fs-10 thin">(The Date when this template (and campaign) go live)</div>
                <div className="fs-10 thin">(Ex. 02/10/25)</div>
              </dt>
              <dd className="col-sm-9">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Launch Date" onChange={this.changeLaunchDate} value={launchDate} />
                </div>
              </dd>

              <dt className="col-sm-3 text-right"></dt>
              <dd className="col-sm-9">
                <button type="button" className="btn btn-warning" onClick={this.editRecord}>Update Brand Template</button>
              </dd>
            </dl>

            <Errors errors={errors} />
          </div>
        </div>
      </div>
    );
  }
}
