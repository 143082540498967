import React from "react";
import BrandPortalNav from "./BrandPortalNav";

export default class CampaignSetupPreStart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: 'campaign_setup'
    };

    this.setActivePage = this.setActivePage.bind(this)
  }

  componentDidMount() {}

  setActivePage(e) {
    this.setState({
      activePage: e
    })
  }

  goToUrl(url) {
    if (url) {
      window.location.assign(url)
    }
  }

  render() {
    let { brand, showdrop_logo_alt } = this.props;
    let {
      activePage,
    } = this.state;

    let brandTemplateStatus = () => {
      let template = brand.brand_template

      if (template.not_found) {
        // page 14
        return (
          <>
            <div style={{ fontSize:"30px",fontWeight:"bold",marginBottom:"20px" }}>
              You don’t have any upcoming campaigns!
            </div>

            <div style={{ marginBottom:"50px" }}>
              <div style={{ fontSize:"20px",fontWeight:"bold" }}>
                If you would like to book a campaign, email us at info@showdrop.com
              </div>
            </div>
          </>
        )
      } else if (template.locked) {
        // page 16
        return (
          <>
            <div style={{ fontSize:"30px",fontWeight:"bold" }}>
              <i className="fas fa-lock" style={{ marginRight:"10px",fontSize:"22px",position:"relative",top:"-2px" }}></i>

              {template.name}
            </div>

            <div style={{ marginBottom:"50px" }}>
              <div style={{ fontSize:"16px",fontWeight:"bold",marginBottom:"20px" }}>All Tasks Completed - Campaign Locked</div>
              <div style={{ width:"500px",marginBottom:"40px",fontSize:"16px" }}>Your campaign set up is now locked, as your campaign is launching soon! If you need to make a change, contact the Showdrop team.</div>

              <div style={{ width:"400px" }}>
                <div style={{ margin:"auto" }}>
                  <button style={{ display:"flex",padding:"15px 20px 15px 30px",justifyContent:"space-between",borderRadius:"10px",border:"none",backgroundColor:"#20cb9a50",color:"white",fontWeight:"bold",width:"220px" }}>
                    <i className="fas fa-lock" style={{ marginRight:"10px",fontSize:"24px" }}></i>

                    <span style={{ fontSize:"18px" }}>Make Edits</span>

                    <i className="fas fa-angle-right" style={{ fontSize:"26px" }}></i>
                  </button>
                </div>
              </div>
            </div>
          </>
        )
      } else if (template.incomplete) {
        // page 6
        return (
          <>
            <div style={{ fontSize:"30px",fontWeight:"bold" }}>{template.name}</div>

            <div style={{ marginBottom:"50px" }}>
              <div style={{ fontSize:"16px",fontWeight:"bold",marginBottom:"20px" }}>{template.remaining_tasks} Tasks Remaining</div>
              <div style={{ width:"500px",marginBottom:"40px",fontSize:"16px" }}>Click Begin and follow the steps to set up your campaign. You will be able to make changes for up to 1 week before your campaign begins.</div>

              <div style={{ width:"400px" }}>
                <div style={{ margin:"auto" }}>
                  <button style={{ display:"flex",padding:"15px 20px 15px 30px",justifyContent:"space-between",borderRadius:"10px",border:"none",backgroundColor:"#20cb9a",color:"white",fontWeight:"bold",width:"155px" }} onClick={(e) => {e.stopPropagation(); this.goToUrl(brand.campaign_setup_next_step_url)}}>
                    <span style={{ fontSize:"18px" }}>Begin</span>

                    <i className="fas fa-angle-right" style={{ fontSize:"26px" }}></i>
                  </button>
                </div>
              </div>
            </div>
          </>
        )
      } else if (template.complete) {
        // page 11
        return (
          <>
            <div style={{ fontSize:"30px",fontWeight:"bold" }}>{template.name}</div>

            <div style={{ marginBottom:"50px" }}>
              <div style={{ fontSize:"16px",fontWeight:"bold",marginBottom:"20px" }}>All Tasks Completed!</div>
              <div style={{ width:"500px",marginBottom:"40px",fontSize:"16px" }}>Your campaign is set up and ready to launch! If you would like to make edits, click the button below and make edits up to 7 days before your campaign launches.</div>

              <div style={{ width:"400px",display:"flex" }}>
                <div>
                  <button style={{ display:"flex",padding:"15px 20px 15px 30px",justifyContent:"space-between",borderRadius:"10px",border:"none",backgroundColor:"#20cb9a",color:"white",fontWeight:"bold" }} onClick={(e) => {e.stopPropagation(); this.goToUrl(brand.campaign_setup_next_step_url)}}>
                    <span style={{ fontSize:"18px" }}>Make Edits</span>

                    <i className="fas fa-angle-right" style={{ fontSize:"26px" }}></i>
                  </button>
                </div>
              </div>
            </div>
          </>
        )
      }
    }

    return (
      <div className="container-fluid bg-brand-portal-v2 fs-14 admin full-height">
        <div className="row">
          <div className="col col-lg-2" style={{ top:"0",position:"sticky",height:"100vh" }}>
            <BrandPortalNav
              activePage={activePage}
              brand={brand}
              showdropLogo={showdrop_logo_alt}
              onClick={this.setActivePage}
            />
          </div>

          <div className="col col-lg-10">
            <div className="row bg-brand-portal-v2">
              <div className="col">
                <h2 className="caprasimo" style={{ margin:"40px 0 20px" }}>
                  Campaign Setup
                </h2>
              </div>
            </div>

            <div className="row">
              <div className="col bg-white br-10 shadow-light" style={{ padding:"20px 40px",margin:"10px" }}>
                {brandTemplateStatus()}
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
