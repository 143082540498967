import React from "react";
import CampaignRow from "./CampaignRow";
import ProductRow from "./ProductRow";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Bar } from "react-chartjs-2";
import BrandPortalNav from "./BrandPortalNav";

export default class AllWaves extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: 'all_campaigns'
    };
  }

  render() {
    let { brand, showdrop_logo_alt } = this.props;
    let { activePage } = this.state;

    return (
      <div className="container-fluid bg-brand-portal-v2 fs-14 admin full-height">
        <div className="row">
          <div className="col col-lg-2" style={{ top:"0",position:"sticky",height:"100vh" }}>
            <BrandPortalNav
              activePage={activePage}
              brand={brand}
              showdropLogo={showdrop_logo_alt}
              onClick={this.setActivePage}
            />
          </div>

          <div className="col col-lg-10">
            <div className="row bg-brand-portal-v2">
              <div className="col">
                <h2 className="caprasimo" style={{ margin:"40px 0 20px" }}>All Campaigns</h2>
              </div>
            </div>

            {
              brand.waves.map(wave => {
                return (
                  <a href={wave.url} style={{ color:"black",textDecoration:"none" }}>
                    <div className="row">
                      <div className="col bg-white br-10 shadow-light" style={{ padding:"20px",margin:"10px 8px",minHeight:"100px" }}>
                        <div style={{ display:"flex" }}>

                          <div style={{ flex:"1",display:"flex" }}>
                            <div style={{ width:"120px",textAlign:"center",marginTop:"14px" }}>
                              {
                                wave.active ? (
                                  <span style={{ padding:"4px 12px",backgroundColor:"#20cb9a",borderRadius:"10px",color:"white",fontSize:"12px",fontWeight:"bold" }}>ACTIVE</span>
                                ) : (
                                  <span style={{ padding:"4px 12px",backgroundColor:"#616161",borderRadius:"10px",color:"white",fontSize:"12px",fontWeight:"bold" }}>COMPLETE</span>
                                )
                              }
                            </div>

                            <div>
                              <div>
                                <span style={{ fontSize:"20px",fontWeight:"bold" }}>{wave.name}</span>
                              </div>
                              <div style={{ fontSize:"12px" }}>{wave.total_stores} Stores | Launched {wave.launch_date}</div>
                            </div>
                          </div>

                          <div style={{ flex:"1" }}>
                            <div style={{ display:"flex" }}>
                              <div style={{ flex:"1",minWidth:"140px",textAlign:"right" }}>
                                {
                                  wave.products.map(product => {
                                    return (
                                      <img style={{ width:"60px",flex:"1",margin:"0 5px" }} src={product.image} />
                                    )
                                  })
                                }
                              </div>
                              <div style={{ flex:"1" }}>
                                <ul className="no-list" style={{ padding:"0",margin:"0",lineHeight:"1.1" }}>
                                  {
                                    wave.products.map(product => {
                                      return (
                                        <li style={{ padding:"1px 5px" }}>{product.name}</li>
                                      )
                                    })
                                  }
                                  <li style={{ padding:"1px 5px" }}>Name</li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div style={{ flex:"1" }}>
                            <div style={{ display:"flex" }}>
                              <div style={{ flex:"1",textAlign:"center",borderRight:"1px solid black" }}>
                                <div style={{ fontSize:"24px",fontWeight:"bold" }}>{numberWithCommas(wave.samples)}</div>
                                <div>Samples</div>
                              </div>
                              <div style={{ flex:"1",textAlign:"center",borderRight:"1px solid black" }}>
                                <div style={{ fontSize:"24px",fontWeight:"bold" }}>{numberWithCommas(wave.ratings)}</div>
                                <div>Ratings</div>
                              </div>
                              <div style={{ flex:"1",textAlign:"center" }}>
                                <div style={{ fontSize:"24px",fontWeight:"bold" }}>{numberWithCommas(wave.reviews)}</div>
                                <div>Reviews</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                )
              })
            }
          </div>
        </div>
      </div>
    );
  }
}

function SumArray(data) {
  return data.reduce((a, v) => (a = a + v), 0);
}

function numberWithCommas(x) {
  if (x == null) {
    return x
  } else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
function renderTooltip(text) {
  return (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  )
};
