import React from "react";
import { Modal } from "react-bootstrap";
import ProductOption from "./ProductOption";

export default class CreateProduct extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      description: "",
      errors: "",
      filesUploaded: false,
      height: "",
      length: "",
      name: "",
      otherCerts: "",
      otherChecked: false,
      saving: false,
      selectedCerts: [],
      selectedFiles: [],
      selectedRefridgerationAllowances: null,
      selectedRefridgerationNeeds: null,
      showModal: false,
      width: "",
    }

    this.changeCerts = this.changeCerts.bind(this)
    this.changeDescription = this.changeDescription.bind(this)
    this.changeHeight = this.changeHeight.bind(this)
    this.changeLength = this.changeLength.bind(this)
    this.changeName = this.changeName.bind(this)
    this.changeOtherCerts = this.changeOtherCerts.bind(this)
    this.changeRefridgerationsAllowances = this.changeRefridgerationsAllowances.bind(this)
    this.changeRefridgerationsNeeds = this.changeRefridgerationsNeeds.bind(this)
    this.changeWidth = this.changeWidth.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleRefClick = this.handleRefClick.bind(this)
    this.saveProduct = this.saveProduct.bind(this)
  }

  componentDidMount() {
    this.fileInputRef = React.createRef();
  }

  componentWillUnmount() {
    this.state.selectedFiles.forEach((file) => {
      URL.revokeObjectURL(file.previewUrl)
    })
  }

  changeCerts(option, status) {
    let selectedCerts = this.state.selectedCerts

    if (status) {
      selectedCerts.push(option)
    } else {
      selectedCerts = selectedCerts.filter(opt => opt != option)
    }

    this.setState({
      selectedCerts: selectedCerts,
      otherChecked: selectedCerts.includes("Other"),
    })
  }

  changeDescription(e) {
    let description = e.target.value.length <= 200 ? e.target.value : e.target.value.slice(0, 200)

    this.setState({
      description: description
    })
  }

  changeHeight(event) {
    this.setState({
      height: event.target.value
    })
  }

  changeLength(event) {
    this.setState({
      length: event.target.value
    })
  }

  changeName(e) {
    let name = e.target.value.length <= 50 ? e.target.value : e.target.value.slice(0, 50)

    this.setState({
      name: name
    })
  }

  changeOtherCerts(event) {
    this.setState({
      otherCerts: event.target.value
    })
  }

  changeRefridgerationsAllowances(option, status) {
    this.setState({
      selectedRefridgerationAllowances: status ? option : null
    })
  }

  changeRefridgerationsNeeds(option, status) {
    this.setState({
      selectedRefridgerationNeeds: status ? option : null
    })
  }

  changeWidth(event) {
    this.setState({
      width: event.target.value
    })
  }

  handleClose() {
    this.setState({
      showModal: false
    })
  }

  handleFileChange(event) {
    const files = Array.from(event.target.files);

    const newFiles = files.map((file) => ({
      file,
      previewUrl: URL.createObjectURL(file),
      name: file.name
    }))

    this.setState({
      filesUploaded: true,
      selectedFiles: [...this.state.selectedFiles, ...newFiles]
    })
  }

  handleOpen() {
    this.setState({
      showModal: true
    })
  }

  handleRefClick() {
    this.fileInputRef.current.click();
  }

  saveProduct() {
    let that = this;
    let certs = this.state.selectedCerts.concat(this.state.otherCerts.split(','))

    this.setState({
      saving: true,
    })

    const formData = new FormData();
    let data = JSON.stringify({
      brand_id: this.props.brand.id,
      description: this.state.description,
      height: this.state.height,
      length: this.state.length,
      name: this.state.name,
      certifications: certs,
      refridgeration_needs: this.state.selectedRefridgerationNeeds,
      refridgeration_allowances: this.state.selectedRefridgerationAllowances,
      width: this.state.width,
    })
    formData.append('product', data)

    if (this.state.filesUploaded) {
      this.state.selectedFiles.forEach((image) => {
        formData.append('files[]', image.file)
      })
    }

    fetch(this.props.brand.save_product_url, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          saving: false,
          errors: data.errors,
        })
      } else {
        window.location.reload()
      }
    })
  }

  render() {
    let {
      brand,
    } = this.props;
    let {
      description,
      errors,
      filesUploaded,
      height,
      length,
      name,
      otherCerts,
      otherChecked,
      saving,
      selectedCerts,
      selectedFiles,
      selectedRefridgerationAllowances,
      selectedRefridgerationNeeds,
      showModal,
      width,
    } = this.state;

    return (
      <>
        <button style={{ border:"none",padding:"15px 30px",borderRadius:"10px",fontWeight:"bold",fontSize:"16px" }} onClick={this.handleOpen}>
          Create New Product
        </button>

        {
          showModal ? (
            <Modal size="lg" show={showModal} onHide={this.handleClose} centered>
              <Modal.Header closeButton>
                <div style={{ fontSize:"30px",fontWeight:"bold",padding:"10px 20px" }}>New Product</div>
              </Modal.Header>

              <Modal.Body style={{ padding:"20px 40px" }}>
                <div style={{ marginBottom:"50px" }}>
                  <div style={{ fontSize:"20px",fontWeight:"bold" }}>1. Product Name</div>
                  <div style={{ fontSize:"14px" }}>50 Character Max (<span style={{ color:`${name && name.length >= 50 ? 'red' : 'black'}` }}>{name ? name.length : 0}</span>/50)</div>
                  <div>
                    <div className="input-group mb-3 mt-3" style={{ width:"600px" }}>
                      <input type="text" className="form-control" placeholder="Enter Product Name" onChange={this.changeName} value={name} />
                    </div>
                  </div>
                </div>

                <div style={{ marginBottom:"50px" }}>
                  <div style={{ fontSize:"20px",fontWeight:"bold" }}>2. Product Description</div>
                  <div style={{ fontSize:"14px" }}>200 Character Max (<span style={{ color:`${description && description.length >= 200 ? 'red' : 'black'}` }}>{description ? description.length : 0}</span>/200)</div>
                  <div>
                    <div className="input-group mb-3 mt-3" style={{ width:"600px" }}>
                      <textarea className="form-control" style={{ height:"150px",padding:"10px 20px",borderRadius:"10px",resize:"none" }} placeholder="Enter Product Description" onChange={this.changeDescription} value={description} />
                    </div>
                  </div>
                </div>

                <div style={{ marginBottom:"50px" }}>
                  <div style={{ fontSize:"20px",fontWeight:"bold" }}>3. Product Certifications</div>
                  <div style={{ display:"flex",flexFlow:"wrap",margin:"20px 0 0" }}>
                    {brand.product_certs.map(option => {
                      return (
                        <ProductOption
                          fullWidth={false}
                          option={option}
                          checked={selectedCerts.includes(option)}
                          onChange={this.changeCerts}
                        />
                      )
                    })}
                  </div>

                  {
                    otherChecked ? (
                      <div className="form-group">
                        <label style={{ margin:"0",fontSize:"12px",paddingLeft:"4px" }}>Seperate choices by a comma</label>

                        <div className="input-group mb-3" style={{ width:"600px" }}>
                          <input type="text" className="form-control" placeholder="Enter Other Certifications" onChange={this.changeOtherCerts} value={otherCerts} />
                        </div>
                      </div>
                    ) : (
                      null
                    )
                  }
                </div>

                <div style={{ marginBottom:"50px" }}>
                  <div style={{ fontSize:"20px",fontWeight:"bold" }}>4. Product Dimensions</div>
                  <div style={{ fontSize:"14px" }}>Please let us know the rough dimensions of the product. This will help us be!er plan and allocate space for your product in our calendar.</div>
                  <div>
                    <img src={brand.product_dimensions_image} style={{ width:"400px" }} />
                  </div>
                  <div style={{ display:"flex",width:"600px" }}>
                    <div style={{ flex:"1",padding:"0 10px" }}>
                      <div style={{ fontWeight:"bold" }}>Height</div>
                      <div>
                        <div className="input-group mb-3 mt-3">
                          <input type="number" className="form-control" placeholder="Inches" onChange={this.changeHeight} value={height} />
                        </div>
                      </div>
                    </div>

                    <div style={{ flex:"1",padding:"0 10px" }}>
                      <div style={{ fontWeight:"bold" }}>Width</div>
                      <div>
                        <div className="input-group mb-3 mt-3">
                          <input type="number" className="form-control" placeholder="Inches" onChange={this.changeWidth} value={width} />
                        </div>
                      </div>
                    </div>

                    <div style={{ flex:"1",padding:"0 10px" }}>
                      <div style={{ fontWeight:"bold" }}>Length</div>
                      <div>
                        <div className="input-group mb-3 mt-3">
                          <input type="number" className="form-control" placeholder="Inches" onChange={this.changeLength} value={length} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ marginBottom:"50px" }}>
                  <div style={{ fontSize:"20px",fontWeight:"bold" }}>5. Does the product <span style={{ textDecoration:"underline" }}>need</span> to be refrigerated?</div>
                  <div style={{ fontSize:"14px" }}>Please choose one.</div>
                  <div className="mt-3">
                    {brand.refridgeration_needs_options.map(option => {
                      return (
                        <ProductOption
                          fullWidth={true}
                          option={option}
                          checked={selectedRefridgerationNeeds === option}
                          onChange={this.changeRefridgerationsNeeds}
                        />
                      )
                    })}
                  </div>
                </div>

                <div style={{ marginBottom:"50px" }}>
                  <div style={{ fontSize:"20px",fontWeight:"bold" }}>6. Can your samples be refridgerated?</div>
                  <div style={{ fontSize:"14px" }}>We might combine your samples with other refridgerated products and need to understand if this is possible or not. Please choose one.</div>
                  <div className="mt-3">
                    {brand.refridgeration_allowances_options.map(option => {
                      return (
                        <ProductOption
                          fullWidth={true}
                          option={option}
                          checked={selectedRefridgerationAllowances === option}
                          onChange={this.changeRefridgerationsAllowances}
                        />
                      )
                    })}
                  </div>
                </div>

                <div style={{ marginBottom:"50px" }}>
                  <div style={{ fontSize:"20px",fontWeight:"bold" }}>7. Product Image</div>
                  <div style={{ display:"flex" }}>
                    <div style={{ flex:"1" }}>
                      <div style={{ fontSize:"14px" }}>Please upload a photo of the product.</div>
                      <div className="mt-3" style={{ fontSize:"14px" }}>This photo will be shown to in-store shoppers on Remove from the kiosk display. See example on the right:</div>
                      <div>
                        <input type="file" style={{ display:"none" }} ref={this.fileInputRef} onChange={this.handleFileChange} multiple />

                        <div className="clickable" style={{ fontSize:"20px",fontWeight:"bold",margin:"20px 0" }} onClick={this.handleRefClick}>
                          {
                            filesUploaded ? (
                              <i style={{ fontSize:"30px",color:"#20cb9a" }} className="fas fa-check-circle"></i>
                            ) : (
                              <i style={{ fontSize:"30px" }} className="fas fa-image"></i>
                            )
                          }

                          <span style={{ marginLeft:"20px",position:"relative",top:"-4px" }}>
                            {
                              filesUploaded ? (
                                "Photo has been Added!"
                              ) : (
                                "Click to Attach Image"
                              )
                            }
                          </span>
                        </div>

                        {
                          selectedFiles.length > 0 ? (
                            selectedFiles.map((file, idx) => {
                              return (
                                <div className="target" key={idx}>
                                  <img src={file.previewUrl} style={{ width:"200px" }} />
                                  <div>{file.name}</div>
                                </div>
                              )
                            })
                          ) : (
                            null
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div style={{ margin:"auto",textAlign:"center" }}>
                    <button style={{ padding:"15px 30px",borderRadius:"10px",border:"none",backgroundColor:"#20cb9a",color:"white",fontWeight:"bold",width:"300px" }} onClick={saving ? null : this.saveProduct} disabled={saving ? 'disabled' : null}>
                      <span style={{ fontSize:"18px",paddingRight:"10px" }}>
                        {
                          saving ? (
                            <>
                              <span style={{ paddingRight:"4px" }}>Saving</span>

                              <i className="fas fa-circle-notch fa-spin"></i>
                            </>
                          ) : (
                            "Save New Product"
                          )
                        }
                      </span>
                    </button>
                  </div>
                </div>

                {
                  errors ? (
                    <div style={{ padding:"5px 0 0",fontSize:"14px",textAlign:"center",color:"red" }}>{errors}</div>
                  ) : (
                    null
                  )
                }
              </Modal.Body>
            </Modal>
          ) : (
            null
          )
        }
      </>
    )
  }
}
