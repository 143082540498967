import React from "react";
import BrandPortalNav from "./BrandPortalNav";
import BrandOption from "./setup_one/BrandOption";

export default class CampaignSetupStepOne extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: 'campaign_setup',
      description: this.props.brand.brand_template.description,
      errors: '',
      filesUploaded: false,
      loading: false,
      newlySelectedFiles: [],
      selectedFiles: this.props.brand.brand_template.logos,
      selectedOptions: this.props.brand.brand_template.demographics,
    };

    this.changeDescription = this.changeDescription.bind(this)
    this.changeOptions = this.changeOptions.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.handleRefClick = this.handleRefClick.bind(this)
    this.handleRemoveFile = this.handleRemoveFile.bind(this)
    this.saveProgress = this.saveProgress.bind(this)
    this.setActivePage = this.setActivePage.bind(this)
  }

  componentDidMount() {
    this.fileInputRef = React.createRef();
  }

  componentWillUnmount() {
    this.state.selectedFiles.forEach((file) => {
      URL.revokeObjectURL(file.previewUrl)
    })
  }

  changeDescription(e) {
    let description = e.target.value.length <= 2000 ? e.target.value : e.target.value.slice(0, 2000)

    this.setState({
      description: description,
    })
  }

  changeOptions(option, status) {
    let selectedOptions = this.state.selectedOptions

    if (status == true) {
      selectedOptions.push(option)
    } else {
      selectedOptions = selectedOptions.filter(opt => opt != option)
    }

    this.setState({
      selectedOptions: selectedOptions
    })
  }

  handleFileChange(event) {
    const files = Array.from(event.target.files);

    const newFiles = files.map((file) => ({
      file,
      previewUrl: URL.createObjectURL(file),
      name: file.name
    }))

    this.setState({
      filesUploaded: true,
      selectedFiles: [...this.state.selectedFiles, ...newFiles],
      newlySelectedFiles: newFiles,
    })
  }

  handleRemoveFile(index) {
    const updatedFiles = [...this.state.selectedFiles]

    URL.revokeObjectURL(updatedFiles[index].previewUrl)
    updatedFiles.splice(index, 1)

    this.setState({
      selectedFiles: updatedFiles
    })
  }

  handleRefClick() {
    this.refs.fileInputRef.click();
  }

  saveProgress() {
    let that = this;

    this.setState({
      loading: true,
    })

    const formData = new FormData();
    let data = JSON.stringify({
      demographics: this.state.selectedOptions,
      description: this.state.description,
    })
    formData.append('brand', data)

    if (this.state.filesUploaded) {
      this.state.newlySelectedFiles.forEach((image) => {
        formData.append('files[]', image.file)
      })
    }

    fetch(this.props.brand.campaign_setup_save_url, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          loading: false,
          errors: data.errors,
        })
      } else {
        this.goToUrl(that.props.brand.campaign_setup_next_step_url)
      }
    })
  }

  setActivePage(e) {
    this.setState({
      activePage: e
    })
  }

  goToUrl(url) {
    if (url) {
      window.location.assign(url)
    }
  }

  render() {
    let { brand, showdrop_logo_alt } = this.props;
    let {
      activePage,
      description,
      errors,
      filesUploaded,
      loading,
      selectedFiles,
    } = this.state;

    return (
      <div className="container-fluid bg-brand-portal-v2 fs-14 admin full-height">
        <div className="row">
          <div className="col col-lg-2" style={{ top:"0",position:"sticky",height:"100vh" }}>
            <BrandPortalNav
              activePage={activePage}
              brand={brand}
              showdropLogo={showdrop_logo_alt}
              onClick={this.setActivePage}
            />
          </div>

          <div className="col col-lg-10">
            <div className="row bg-brand-portal-v2">
              <div className="col">
                <h2 className="caprasimo" style={{ margin:"40px 0 20px" }}>
                  Campaign Setup
                </h2>
              </div>
            </div>

            <div className="row bg-brand-portal-v2">
              <div className="progress-container shadow-light">
                <div className="step">
                  <div style={{ height:"35px",color:"black",textAlign:"left",lineHeight:"1.1",marginLeft:"50px" }}>
                    <p style={{ margin:"0" }}>Brand</p>
                    <p>Information</p>
                  </div>

                  <div style={{ display:"flex" }}>
                    <div style={{ flex:"1",maxWidth:"40px",border:"3px solid black",color:"black" }} className="step-number">1</div>
                    <div style={{ flex:"1",marginTop:"18px",borderTop:"4px solid black" }}></div>
                  </div>
                </div>

                <div className="step">
                  <div style={{ height:"35px",color:"#ccc",textAlign:"left",lineHeight:"1.1",marginLeft:"50px" }}>
                    <p style={{ margin:"0" }}>Product</p>
                    <p>Information</p>
                  </div>

                  <div style={{ display:"flex" }}>
                    <div style={{ flex:"1",maxWidth:"40px",border:"3px solid #ccc" }} className="step-number">2</div>
                    <div style={{ flex:"1",marginTop:"18px",borderTop:"4px solid #ccc" }}></div>
                  </div>
                </div>

                <div className="step">
                  <div style={{ height:"35px",color:"#ccc",textAlign:"left",lineHeight:"1.1",marginLeft:"50px" }}>
                    <p style={{ margin:"0" }}>Campaign</p>
                    <p>Details</p>
                  </div>

                  <div style={{ display:"flex" }}>
                    <div style={{ flex:"1",maxWidth:"40px",border:"3px solid #ccc" }} className="step-number">3</div>
                    <div style={{ flex:"1",marginTop:"18px",borderTop:"4px solid #ccc" }}></div>
                  </div>
                </div>

                <div className="step">
                  <div style={{ height:"35px",color:"#ccc",textAlign:"left",lineHeight:"3.5",marginLeft:"50px" }}>
                    <p>Complete</p>
                  </div>

                  <div style={{ display:"flex" }}>
                    <div style={{ flex:"1",maxWidth:"40px",border:"3px solid #ccc" }} className="step-number">
                      <i className="fas fa-check"></i>
                    </div>
                    <div style={{ flex:"1" }}></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col bg-white br-10 shadow-light" style={{ padding:"30px 40px",margin:"10px" }}>
                <div style={{ marginBottom:"50px" }}>
                  <div style={{ fontSize:"20px",fontWeight:"bold" }}>1. Brand Description</div>
                  <div>2000 Character Max (<span style={{ color:`${description && description.length >= 2000 ? 'red' : 'black'}` }}>{description ? description.length : 0}</span>/2000)</div>
                  <div>
                    <div className="input-group mb-3 mt-3" style={{ width:"600px" }}>
                      <textarea className="form-control" style={{ height:"150px",padding:"10px 20px",borderRadius:"10px",resize:"none" }} placeholder="Enter Brand Description" onChange={this.changeDescription} value={description} />
                    </div>
                  </div>
                </div>

                <div style={{ marginBottom:"44px" }}>
                  <div style={{ fontSize:"20px",fontWeight:"bold" }}>2. Please let us know if any of the following apply to your brand</div>
                  <div>(select all that apply, leave blank if not applicable)</div>
                  <div style={{ display:"flex",flexFlow:"wrap",margin:"20px 0" }}>
                    {brand.brand_options.map(option => {
                      return (
                        <BrandOption
                          checked={this.state.selectedOptions.indexOf(option) > -1}
                          option={option}
                          onChange={this.changeOptions}
                        />
                      )
                    })}
                  </div>
                </div>

                <div style={{ marginBottom:"50px" }}>
                  <div style={{ fontSize:"20px",fontWeight:"bold" }}>3. Brand Logo</div>
                  <div>Please upload a photo of your brand's logo.</div>
                  <div>
                    <input type="file" style={{ display:"none" }} ref="fileInputRef" onChange={this.handleFileChange} multiple />

                    <div className="clickable" style={{ fontSize:"20px",fontWeight:"bold",margin:"20px 0" }} onClick={this.handleRefClick}>
                      {
                        filesUploaded ? (
                          <i style={{ fontSize:"30px",color:"#20cb9a" }} className="fas fa-check-circle"></i>
                        ) : (
                          <i style={{ fontSize:"30px" }} className="fas fa-image"></i>
                        )
                      }

                      <span style={{ marginLeft:"20px",position:"relative",top:"-4px" }}>
                        {
                          filesUploaded ? (
                            "Logo has been Added!"
                          ) : (
                            "Click to Attach Image"
                          )
                        }
                      </span>
                    </div>

                    {
                      selectedFiles.length > 0 ? (
                        selectedFiles.map((file, idx) => {
                          return (
                            <div className="target" key={idx}>
                              <img src={file.previewUrl} style={{ width:"200px" }} />
                              <div>{file.name}</div>
                            </div>
                          )
                        })
                      ) : (
                        null
                      )
                    }
                  </div>
                </div>

                <div style={{ display:"flex",width:"400px",margin:"auto" }}>
                  <div style={{ margin:"auto",textAlign:"center" }}>
                    <button style={{ display:"flex",padding:"15px 30px 15px 20px",justifyContent:"space-between",borderRadius:"10px",border:"none",backgroundColor:"#DCDCDC",color:"black",fontWeight:"bold",width:"155px" }} onClick={loading ? null : (e) => {e.stopPropagation(); this.goToUrl(brand.campaign_setup_previous_step_url)}} disabled={loading ? 'disabled' : null}>
                      <i className="fas fa-angle-left" style={{ fontSize:"26px" }}></i>

                      <span style={{ fontSize:"18px" }}>Back</span>
                    </button>
                  </div>

                  <div style={{ margin:"auto",textAlign:"center" }}>
                    <button style={{ display:"flex",padding:"15px 20px 15px 30px",justifyContent:"space-between",borderRadius:"10px",border:"none",backgroundColor:"#20cb9a",color:"white",fontWeight:"bold",width:"155px" }} onClick={loading ? null : this.saveProgress} disabled={loading ? 'disabled' : null}>
                      <span style={{ fontSize:"18px" }}>Confirm</span>

                      <i className={`fas ${loading ? 'fa-circle-notch fa-spin' : 'fa-angle-right'}`} style={{ fontSize:"26px" }}></i>
                    </button>
                  </div>
                </div>

                {
                  errors ? (
                    <div style={{ padding:"5px 0 0",fontSize:"14px",textAlign:"center",color:"red" }}>{errors}</div>
                  ) : (
                    null
                  )
                }
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
