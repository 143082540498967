import React from "react";
import BrandPortalNav from "./BrandPortalNav";
import CreateProduct from "./setup_two/CreateProduct";
import ViewProduct from "./setup_two/ViewProduct";

export default class CampaignSetupStepTwo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: 'campaign_setup',
      errors: "",
      loading: false,
      productsInCampaign: this.props.brand.products_in_campaign,
      productsOutCampaign: this.props.brand.products_out_campaign,
    };

    this.addToCampaign = this.addToCampaign.bind(this)
    this.removeFromCampaign = this.removeFromCampaign.bind(this)
    this.saveProgress = this.saveProgress.bind(this)
    this.setActivePage = this.setActivePage.bind(this)
    this.updateProductsInCampaign = this.updateProductsInCampaign.bind(this)
  }

  addToCampaign(name) {
    let productsInCampaign = this.state.productsInCampaign
    let productsOutCampaign = this.state.productsOutCampaign

    let foundProduct = productsOutCampaign.find(pro => pro.name == name)

    productsInCampaign.push(foundProduct)
    productsOutCampaign = productsOutCampaign.filter(product => product != foundProduct)

    this.setState({
      productsInCampaign: productsInCampaign,
      productsOutCampaign: productsOutCampaign,
    })
  }

  removeFromCampaign(name) {
    if(confirm("Are you sure you want to remove this product from this campaign?")) {
      let productsInCampaign = this.state.productsInCampaign
      let productsOutCampaign = this.state.productsOutCampaign

      let foundProduct = productsInCampaign.find(pro => pro.name == name)

      productsOutCampaign.push(foundProduct)
      productsInCampaign = productsInCampaign.filter(product => product != foundProduct)

      this.setState({
        productsInCampaign: productsInCampaign,
        productsOutCampaign: productsOutCampaign,
      })
    }
  }

  saveProgress() {
    let that = this;

    this.setState({
      loading: true,
    })

    const formData = new FormData();
    let data = JSON.stringify({
      brand_id: this.props.brand.id,
      products_in_campaign: this.state.productsInCampaign,
    })
    formData.append('brand', data)

    fetch(this.props.brand.campaign_setup_save_url, {
      method: 'POST',
      headers: {},
      body: formData
    }).then(response => {
      return response.json();
    }).then((data) => {
      if (data.errors) {
        that.setState({
          loading: false,
          errors: data.errors,
        })
      } else {
        this.goToUrl(that.props.brand.campaign_setup_next_step_url)
      }
    })
  }

  setActivePage(e) {
    this.setState({
      activePage: e
    })
  }

  updateProductsInCampaign(productsInCampaign, productsOutCampaign) {
    this.setState({
      productsInCampaign: productsInCampaign,
      productsOutCampaign: productsOutCampaign,
    })
  }

  goToUrl(url) {
    if (url) {
      window.location.assign(url)
    }
  }

  render() {
    let { brand, showdrop_logo_alt } = this.props;
    let {
      activePage,
      errors,
      loading,
      productsInCampaign,
      productsOutCampaign,
    } = this.state;

    return (
      <div className="container-fluid bg-brand-portal-v2 fs-14 admin full-height">
        <div className="row">
          <div className="col col-lg-2" style={{ top:"0",position:"sticky",height:"100vh" }}>
            <BrandPortalNav
              activePage={activePage}
              brand={brand}
              showdropLogo={showdrop_logo_alt}
              onClick={this.setActivePage}
            />
          </div>

          <div className="col col-lg-10">
            <div className="row bg-brand-portal-v2">
              <div className="col">
                <h2 className="caprasimo" style={{ margin:"40px 0 20px" }}>
                  Campaign Setup
                </h2>
              </div>
            </div>

            <div className="row bg-brand-portal-v2">
              <div className="progress-container shadow-light">
                <div className="step">
                  <div style={{ height:"35px",color:"#ccc",textAlign:"left",lineHeight:"1.1",marginLeft:"50px" }}>
                    <p style={{ margin:"0" }}>Brand</p>
                    <p>Information</p>
                  </div>

                  <div style={{ display:"flex" }}>
                    <div style={{ flex:"1",maxWidth:"40px",color:"white",border:"3px solid #20cb9a",backgroundColor:"#20cb9a" }} className="step-number">
                      <i className="fas fa-check"></i>
                    </div>
                    <div style={{ flex:"1",marginTop:"18px",borderTop:"4px solid #20cb9a",color:"#20cb9a" }}></div>
                  </div>
                </div>

                <div className="step">
                  <div style={{ height:"35px",color:"black",textAlign:"left",lineHeight:"1.1",marginLeft:"50px" }}>
                    <p style={{ margin:"0" }}>Product</p>
                    <p>Information</p>
                  </div>

                  <div style={{ display:"flex" }}>
                    <div style={{ flex:"1",maxWidth:"40px",border:"3px solid black",color:"black" }} className="step-number">2</div>
                    <div style={{ flex:"1",marginTop:"18px",borderTop:"4px solid black" }}></div>
                  </div>
                </div>

                <div className="step">
                  <div style={{ height:"35px",color:"#ccc",textAlign:"left",lineHeight:"1.1",marginLeft:"50px" }}>
                    <p style={{ margin:"0" }}>Campaign</p>
                    <p>Details</p>
                  </div>

                  <div style={{ display:"flex" }}>
                    <div style={{ flex:"1",maxWidth:"40px",border:"3px solid #ccc" }} className="step-number">3</div>
                    <div style={{ flex:"1",marginTop:"18px",borderTop:"4px solid #ccc" }}></div>
                  </div>
                </div>

                <div className="step">
                  <div style={{ height:"35px",color:"#ccc",textAlign:"left",lineHeight:"3.5",marginLeft:"50px" }}>
                    <p>Complete</p>
                  </div>

                  <div style={{ display:"flex" }}>
                    <div style={{ flex:"1",maxWidth:"40px",border:"3px solid #ccc" }} className="step-number">
                      <i className="fas fa-check"></i>
                    </div>
                    <div style={{ flex:"1" }}></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col bg-white br-10 shadow-light" style={{ padding:"20px 40px",margin:"10px" }}>
                <div style={{ marginBottom:"20px" }}>
                  <div style={{ fontSize:"20px",fontWeight:"bold" }}>1. Select Products that will be included in campaign</div>
                  <div></div>
                </div>

                <div className="row">
                  <div className="col">
                    <CreateProduct
                      brand={brand}
                      updateProductsInCampaign={this.updateProductsInCampaign}
                      productsInCampaign={productsInCampaign}
                      productsOutCampaign={productsOutCampaign}
                    />
                  </div>
                  <div className="col"></div>
                </div>

                <div className="row" style={{ padding:"20px 0" }}>
                  <div className="col" style={{ padding:"10px 20px",minHeight:"400px" }}>
                    <div style={{ fontSize:"16px",fontWeight:"bold" }}>Products to be Sampled for this Campaign</div>

                    <div style={{ marginTop:"10px" }}>
                      <ul style={{ margin:"0",padding:"0",listStyleType:"none" }}>
                        {productsInCampaign.map(product => {
                          return (
                            <li style={{ padding:"5px",borderRadius:"10px",border:"2px solid #20cb9a",marginBottom:"10px",backgroundColor:"#20cb9a20" }}>
                              <div style={{ display:"flex" }}>
                                <div style={{ flex:"1",maxWidth:"120px",textAlign:"center",marginRight:"4px" }}>
                                  <img src={product.photos.last ? product.photos.last.previewUrl : null} style={{ width:"120px" }} />
                                </div>

                                <div style={{ flex:"1" }}>
                                  <div style={{ fontSize:"16px",fontWeight:"bold",marginBottom:"5px" }}>{product.name}</div>
                                </div>

                                <div style={{ flex:"1",display:"flex",maxWidth:"100px" }}>
                                  <ViewProduct
                                    product={product}
                                    brand={brand}
                                    updateProductsInCampaign={this.updateProductsInCampaign}
                                    productsInCampaign={productsInCampaign}
                                    productsOutCampaign={productsOutCampaign}
                                  />

                                  <div className="clickable" style={{ padding:"5px 10px",backgroundColor:"#ef5c63",color:"white",borderRadius:"10px",margin:"13px 5px",textAlign:"center",fontWeight:"bold",fontSize:"14px" }} onClick={() => this.removeFromCampaign(product.name)}>
                                    <i className="fas fa-trash-alt"></i>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>

                  <div className="col" style={{ borderLeft:"1px solid black",padding:"10px 20px",minHeight:"400px" }}>
                    <div style={{ fontSize:"16px",fontWeight:"bold" }}>Previously Saved Products</div>

                    <div style={{ marginTop:"10px" }}>
                      <ul style={{ margin:"0",padding:"0",listStyleType:"none" }}>
                        {productsOutCampaign.map(product => {
                          return (
                            <li style={{ padding:"5px",borderRadius:"10px",border:"2px solid #c7c7c7",marginBottom:"10px" }}>
                              <div style={{ display:"flex" }}>
                                <div style={{ flex:"1",maxWidth:"100px",textAlign:"center" }}>
                                  <img src={product.photos.last ? product.photos.last.previewUrl : null} style={{ width:"100px" }} />
                                </div>

                                <div style={{ flex:"1" }}>
                                  <div style={{ fontSize:"16px",fontWeight:"bold",marginBottom:"5px",color:"#c7c7c7" }}>{product.name}</div>
                                </div>

                                <div style={{ flex:"1",display:"flex",maxWidth:"100px" }}>
                                  <ViewProduct
                                    product={product}
                                    brand={brand}
                                    updateProductsInCampaign={this.updateProductsInCampaign}
                                    productsInCampaign={productsInCampaign}
                                    productsOutCampaign={productsOutCampaign}
                                  />

                                  <div className="clickable" style={{ padding:"5px 10px",backgroundColor:"#20cb9a",color:"white",borderRadius:"10px",margin:"13px 5px",textAlign:"center",fontWeight:"bold",fontSize:"14px" }} onClick={() => this.addToCampaign(product.name)}>
                                    <i className="fas fa-plus"></i>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                </div>

                <div style={{ display:"flex",width:"400px",margin:"auto" }}>
                  <div style={{ margin:"auto",textAlign:"center" }}>
                    <button style={{ display:"flex",padding:"15px 30px 15px 20px",justifyContent:"space-between",borderRadius:"10px",border:"none",backgroundColor:"#DCDCDC",color:"black",fontWeight:"bold",width:"155px" }} onClick={loading ? null : (e) => {e.stopPropagation(); this.goToUrl(brand.campaign_setup_previous_step_url)}} disabled={loading ? 'disabled' : null}>
                      <i className="fas fa-angle-left" style={{ fontSize:"26px" }}></i>

                      <span style={{ fontSize:"18px" }}>Back</span>
                    </button>
                  </div>

                  <div style={{ margin:"auto",textAlign:"center" }}>
                    <button style={{ display:"flex",padding:"15px 20px 15px 30px",justifyContent:"space-between",borderRadius:"10px",border:"none",backgroundColor:"#20cb9a",color:"white",fontWeight:"bold",width:"155px" }} onClick={loading ? null : this.saveProgress} disabled={loading ? 'disabled' : null}>
                      <span style={{ fontSize:"18px" }}>Confirm</span>

                      <i className={`fas ${loading ? 'fa-circle-notch fa-spin' : 'fa-angle-right'}`} style={{ fontSize:"26px" }}></i>
                    </button>
                  </div>
                </div>

                {
                  errors ? (
                    <div style={{ padding:"5px 0 0",fontSize:"14px",textAlign:"center",color:"red" }}>{errors}</div>
                  ) : (
                    null
                  )
                }
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
