import React from "react";

export default class ProductOption extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this)
  }

  onChange() {
    this.props.onChange(this.props.option, !this.props.checked)
  }

  render() {
    let { checked, fullWidth, option } = this.props;

    return (
      <div style={{ marginLeft:"20px",width:`${fullWidth ? '100%' : '200px'}`,marginBottom:"10px" }}>
        <input style={{ scale:"1.5" }} type="checkbox" className="form-check-input clickable" onChange={this.onChange} checked={checked} />

        <label className="clickable" style={{ marginLeft:"5px" }} onClick={this.onChange}>{option}</label>
      </div>
    )
  }
}
