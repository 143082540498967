import React from "react"
import Nav from "../shared/Nav";
import ShowHeader from "../shared/headers/ShowHeader";
import IndexHeader from "../shared/headers/IndexHeader";
import AdminHeader from "../shared/headers/AdminHeader";

export default class Show extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      onDesktop: true
    }
  }

  componentDidMount() {
    let onDesktop = window && window.innerWidth >= 1000;

    this.setState({
      onDesktop: onDesktop
    })
  }

  render () {
    let { brand_template, showdrop_logo, url_new } = this.props;
    let { onDesktop } = this.state;
    let pageName = 'brands';

    let listItems = (list) => {
      if (list.length > 0) {
        return (
          list.map((item) => {
            return (
              <div>- {item}</div>
            );
          })

        )
      } else {
        return "-"
      }
    }

    return (
      <div className="container-fluid bg-extra-gray fs-14">
        <AdminHeader
          logo={showdrop_logo}
          onDesktop={onDesktop}
          pageName={pageName}
          counts={this.props.counts}
        />

        <div className="row full-height">
          <div className={`col col-lg-2 nav-restrict-size ${onDesktop ? '' : 'hide'}`}>
            <Nav
              active={pageName}
              counts={this.props.counts}
            />
          </div>

          <div className="col bg-white">
            <ShowHeader
              name={`${brand_template.name}`}
              subtext={null}
              url_edit={brand_template.url_edit}
              url_new={url_new}
            />

            <dl className="row">
              <dt className="col-sm-3 text-right">ID</dt>
              <dd className="col-sm-9">{brand_template.id}</dd>

              <dt className="col-sm-3 text-right">Name</dt>
              <dd className="col-sm-9">{brand_template.name}</dd>

              <dt className="col-sm-3 text-right">Close Date</dt>
              <dd className="col-sm-9">{brand_template.close_date}</dd>

              <dt className="col-sm-3 text-right">Launch Date</dt>
              <dd className="col-sm-9">{brand_template.launch_date}</dd>

              <dt className="col-sm-3 text-right">Description</dt>
              <dd className="col-sm-9">{brand_template.description || '-'}</dd>

              <dt className="col-sm-3 text-right">Demographics</dt>
              <dd className="col-sm-9">{listItems(brand_template.demographics)}</dd>

              <dt className="col-sm-3 text-right">Rebate Amount</dt>
              <dd className="col-sm-9">{brand_template.rebate_amount || '-'}</dd>

              <dt className="col-sm-3 text-right">Logos</dt>
              <dd className="col-sm-9">
                {
                  brand_template.logos.map(logo => {
                    return (
                      <div>
                        <img className="w70 h-auto" src={logo.file} />
                        <div>{logo.name}</div>
                      </div>
                    )
                  })
                }
              </dd>
            </dl>

            <hr/>

            <div className="col">
              <IndexHeader
                name={'Products'}
                url={null}
              />

              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Image</th>
                    <th scope="col">Name</th>
                    <th scope="col" style={{ width:"200px" }}>Description</th>
                    <th scope="col" style={{ width:"70px" }}>Height</th>
                    <th scope="col" style={{ width:"70px" }}>Length</th>
                    <th scope="col" style={{ width:"70px" }}>Width</th>
                    <th scope="col">Certifications</th>
                    <th scope="col" style={{ width:"200px" }}>
                      Refridgeration
                      <div>Need</div>
                    </th>
                    <th scope="col" style={{ width:"200px" }}>
                      Refridgeration
                      <div>Allowance</div>
                    </th>
                    <th scope="col" style={{ width:"70px" }}>Active</th>
                  </tr>
                </thead>
                <tbody>
                  {brand_template.products.map(product => {
                    return (
                      <tr>
                        <th scope="row">{product.id}</th>
                        <td>
                          {
                            product.photos.length > 0 ? (
                              product.photos.map(photo => {
                                return (
                                  <div>
                                    <img className="w70 h-auto" src={photo.file} />
                                    <div>{photo.name}</div>
                                  </div>
                                )
                              })
                            ) : (
                              null
                            )
                          }
                        </td>
                        <td>{product.name}</td>
                        <td style={{ width:"200px" }}>{product.description}</td>
                        <td style={{ width:"70px" }}>{product.height}</td>
                        <td style={{ width:"70px" }}>{product.length}</td>
                        <td style={{ width:"70px" }}>{product.width}</td>
                        <td>{listItems(product.certifications)}</td>
                        <td style={{ width:"200px" }}>{product.refridgeration_needs}</td>
                        <td style={{ width:"200px" }}>{product.refridgeration_allowances}</td>
                        <td style={{ width:"70px" }}>{product.active ? 'true' : 'false'}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
