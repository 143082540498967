import React from "react";
import BrandPortalNav from "./BrandPortalNav";

export default class CampaignSetupStart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: 'campaign_setup'
    };

    this.setActivePage = this.setActivePage.bind(this)
  }

  componentDidMount() {}

  setActivePage(e) {
    this.setState({
      activePage: e
    })
  }

  goToUrl(url) {
    if (url) {
      window.location.assign(url)
    }
  }

  render() {
    let { brand, showdrop_logo_alt, start_image } = this.props;
    let {
      activePage,
    } = this.state;

    return (
      <div className="container-fluid bg-brand-portal-v2 fs-14 admin full-height">
        <div className="row">
          <div className="col col-lg-2" style={{ top:"0",position:"sticky",height:"100vh" }}>
            <BrandPortalNav
              activePage={activePage}
              brand={brand}
              showdropLogo={showdrop_logo_alt}
              onClick={this.setActivePage}
            />
          </div>

          <div className="col col-lg-10">
            <div className="row bg-brand-portal-v2">
              <div className="col">
                <h2 className="caprasimo" style={{ margin:"40px 0 20px" }}>
                  Campaign Setup
                </h2>
              </div>
            </div>

            <div className="row bg-brand-portal-v2">
              <div className="progress-container shadow-light">
              <div className="step">
                  <div style={{ height:"35px",color:"#ccc",textAlign:"left",lineHeight:"1.1",marginLeft:"50px" }}>
                    <p style={{ margin:"0" }}>Brand</p>
                    <p>Information</p>
                  </div>

                  <div style={{ display:"flex" }}>
                    <div style={{ flex:"1",maxWidth:"40px",border:"3px solid #ccc" }} className="step-number">1</div>
                    <div style={{ flex:"1",marginTop:"18px",borderTop:"4px solid #ccc" }}></div>
                  </div>
                </div>

                <div className="step">
                  <div style={{ height:"35px",color:"#ccc",textAlign:"left",lineHeight:"1.1",marginLeft:"50px" }}>
                    <p style={{ margin:"0" }}>Product</p>
                    <p>Information</p>
                  </div>

                  <div style={{ display:"flex" }}>
                    <div style={{ flex:"1",maxWidth:"40px",border:"3px solid #ccc" }} className="step-number">2</div>
                    <div style={{ flex:"1",marginTop:"18px",borderTop:"4px solid #ccc" }}></div>
                  </div>
                </div>

                <div className="step">
                  <div style={{ height:"35px",color:"#ccc",textAlign:"left",lineHeight:"1.1",marginLeft:"50px" }}>
                    <p style={{ margin:"0" }}>Campaign</p>
                    <p>Details</p>
                  </div>

                  <div style={{ display:"flex" }}>
                    <div style={{ flex:"1",maxWidth:"40px",border:"3px solid #ccc" }} className="step-number">3</div>
                    <div style={{ flex:"1",marginTop:"18px",borderTop:"4px solid #ccc" }}></div>
                  </div>
                </div>

                <div className="step">
                  <div style={{ height:"35px",color:"#ccc",textAlign:"left",lineHeight:"3.5",marginLeft:"50px" }}>
                    <p>Complete</p>
                  </div>

                  <div style={{ display:"flex" }}>
                    <div style={{ flex:"1",maxWidth:"40px",border:"3px solid #ccc" }} className="step-number">
                      <i className="fas fa-check"></i>
                    </div>
                    <div style={{ flex:"1" }}></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col bg-white br-10 shadow-light" style={{ padding:"20px 40px",margin:"10px" }}>
                <div style={{ padding:"40px 0",textAlign:"center" }}>
                  <img src={start_image} />
                </div>

                <div style={{ display:"flex",width:"400px",margin:"auto" }}>
                  <div style={{ margin:"auto",textAlign:"center" }}>
                    <button style={{ display:"flex",padding:"15px 20px 15px 30px",justifyContent:"space-between",borderRadius:"10px",border:"none",backgroundColor:"#20cb9a",color:"white",fontWeight:"bold",width:"155px" }} onClick={(e) => {e.stopPropagation(); this.goToUrl(brand.campaign_setup_next_step_url)}}>
                      <span style={{ fontSize:"18px" }}>Begin</span>

                      <i className="fas fa-angle-right" style={{ fontSize:"26px" }}></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
