import React from "react";

export default class BrandOption extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.checked,
    };

    this.onChange = this.onChange.bind(this)
  }

  onChange() {
    let checked = !this.state.checked

    this.setState({
      checked: checked
    })

    this.props.onChange(this.props.option, checked)
  }

  render() {
    let { option } = this.props;
    let { checked } = this.state;

    return (
      <div style={{ marginLeft:"20px",width:"200px",marginBottom:"10px" }}>
        <input style={{ scale:"1.5" }} type="checkbox" className="form-check-input clickable" onChange={this.onChange} checked={checked} />

        <label className="clickable" style={{ marginLeft:"5px" }} onClick={this.onChange}>{option}</label>
      </div>
    )
  }
}
