import React from "react";
import BrandPortalNav from "./BrandPortalNav";

export default class CampaignSetupStepFour extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: 'campaign_setup'
    };

    this.setActivePage = this.setActivePage.bind(this)
  }

  componentDidMount() {}

  setActivePage(e) {
    this.setState({
      activePage: e
    })
  }

  goToUrl(url) {
    if (url) {
      window.location.assign(url)
    }
  }

  render() {
    let { brand, showdrop_logo_alt } = this.props;
    let {
      activePage,
    } = this.state;

    return (
      <div className="container-fluid bg-brand-portal-v2 fs-14 admin full-height">
        <div className="row">
          <div className="col col-lg-2" style={{ top:"0",position:"sticky",height:"100vh" }}>
            <BrandPortalNav
              activePage={activePage}
              brand={brand}
              showdropLogo={showdrop_logo_alt}
              onClick={this.setActivePage}
            />
          </div>

          <div className="col col-lg-10">
            <div className="row bg-brand-portal-v2">
              <div className="col">
                <h2 className="caprasimo" style={{ margin:"40px 0 20px" }}>Campaign Setup</h2>
              </div>
            </div>

            <div className="row bg-brand-portal-v2">
              <div className="progress-container shadow-light">
                <div className="step">
                  <div style={{ height:"35px",color:"#ccc",textAlign:"left",lineHeight:"1.1",marginLeft:"50px" }}>
                    <p style={{ margin:"0" }}>Brand</p>
                    <p>Information</p>
                  </div>

                  <div style={{ display:"flex" }}>
                    <div style={{ flex:"1",maxWidth:"40px",color:"white",border:"3px solid #20cb9a",backgroundColor:"#20cb9a" }} className="step-number">
                      <i className="fas fa-check"></i>
                    </div>
                    <div style={{ flex:"1",marginTop:"18px",borderTop:"4px solid #20cb9a",color:"#20cb9a" }}></div>
                  </div>
                </div>

                <div className="step">
                  <div style={{ height:"35px",color:"#ccc",textAlign:"left",lineHeight:"1.1",marginLeft:"50px" }}>
                    <p style={{ margin:"0" }}>Product</p>
                    <p>Information</p>
                  </div>

                  <div style={{ display:"flex" }}>
                    <div style={{ flex:"1",maxWidth:"40px",color:"white",border:"3px solid #20cb9a",backgroundColor:"#20cb9a" }} className="step-number">
                      <i className="fas fa-check"></i>
                    </div>
                    <div style={{ flex:"1",marginTop:"18px",borderTop:"4px solid #20cb9a",color:"#20cb9a" }}></div>
                  </div>
                </div>

                <div className="step">
                  <div style={{ height:"35px",color:"#ccc",textAlign:"left",lineHeight:"1.1",marginLeft:"50px" }}>
                    <p style={{ margin:"0" }}>Campaign</p>
                    <p>Details</p>
                  </div>

                  <div style={{ display:"flex" }}>
                    <div style={{ flex:"1",maxWidth:"40px",color:"white",border:"3px solid #20cb9a",backgroundColor:"#20cb9a" }} className="step-number">
                      <i className="fas fa-check"></i>
                    </div>
                    <div style={{ flex:"1",marginTop:"18px",borderTop:"4px solid #20cb9a",color:"#20cb9a" }}></div>
                  </div>
                </div>

                <div className="step">
                  <div style={{ height:"35px",color:"black",textAlign:"left",lineHeight:"3.5",marginLeft:"50px" }}>
                    <p>Complete</p>
                  </div>

                  <div style={{ display:"flex" }}>
                    <div style={{ flex:"1",maxWidth:"40px",border:"3px solid black",color:"black" }} className="step-number">
                      <i className="fas fa-check"></i>
                    </div>
                    <div style={{ flex:"1" }}></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col bg-white br-10 shadow-light" style={{ padding:"20px 40px",margin:"10px" }}>
                <div style={{ textAlign:"center",marginBottom:"50px" }}>
                  {
                    brand.brand_template.complete ? (
                      <div style={{ color:"#20cb9a",fontSize:"50px",marginTop:"20px" }}>
                        <i className="fas fa-check-circle"></i>
                      </div>
                    ) : (
                      <div style={{ color:"#ef5c63",fontSize:"50px",marginTop:"20px" }}>
                        <i class="fas fa-exclamation-circle"></i>
                      </div>
                    )
                  }
                  <div style={{ fontWeight:"bold",fontSize:"20px",marginBottom:"20px" }}>
                    {
                      brand.brand_template.complete ? (
                        "Setup Complete!"
                      ) : (
                        "Setup Incomplete"
                      )
                    }
                  </div>
                  <div style={{ width:"500px",margin:"auto",fontSize:"14px" }}>
                    {
                      brand.brand_template.complete ? (
                        `You may continue to make changes until ${brand.brand_template.close_date}`
                      ) : (
                        `Please complete all required steps by ${brand.brand_template.close_date}`
                      )
                    }
                  </div>
                </div>

                <div style={{ display:"flex",width:"400px",margin:"auto" }}>
                  <div style={{ margin:"auto",textAlign:"center" }}>
                    <button style={{ display:"flex",padding:"15px 30px 15px 20px",justifyContent:"space-between",borderRadius:"10px",border:"none",backgroundColor:"#DCDCDC",color:"black",fontWeight:"bold",width:"155px" }} onClick={(e) => {e.stopPropagation(); this.goToUrl(brand.campaign_setup_previous_step_url)}}>
                      <i className="fas fa-angle-left" style={{ fontSize:"26px" }}></i>

                      <span style={{ fontSize:"18px" }}>Back</span>
                    </button>
                  </div>

                  <div style={{ margin:"auto",textAlign:"center" }}>
                    <button style={{ display:"flex",padding:"15px 20px 15px 30px",justifyContent:"space-between",borderRadius:"10px",border:"none",backgroundColor:"#20cb9a",color:"white",fontWeight:"bold",width:"155px" }} onClick={(e) => {e.stopPropagation(); this.goToUrl(brand.campaign_setup_next_step_url)}}>
                      <span style={{ fontSize:"18px" }}>Confirm</span>

                      <i className="fas fa-angle-right" style={{ fontSize:"26px" }}></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
